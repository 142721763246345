<template>
  <g>
    <svg:style>
      .cls-10,
      .cls-11,
      .cls-12,
      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-8,
      .cls-9 {
        stroke: #aeaeae;
        stroke-width: 0.25px;
      }

      .cls-10,
      .cls-11,
      .cls-12,
      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-8,
      .cls-9 {
        stroke-linecap: round;
        stroke-linejoin: round;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#inox-gradient-2);
      }

      .cls-6 {
        fill: url(#inox-gradient-3);
      }

      .cls-7 {
        fill: url(#inox-gradient-4);
      }

      .cls-8 {
        fill: url(#inox-gradient-5);
      }

      .cls-9 {
        fill: url(#inox-gradient-6);
      }

      .cls-10 {
        fill: url(#inox-gradient-7);
      }

      .cls-11 {
        fill: url(#inox-gradient-8);
      }

      .cls-12 {
        fill: url(#glass-pattern);
      }
    </svg:style>

    <linearGradient id="handle-gradient"
                    :x1="doorLeftWidth + 11.33"
                    :y1="doorTopHeight + 150.96"
                    :x2="doorLeftWidth + 17.01"
                    :y2="doorTopHeight + 150.96"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>

    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth + 64.2"
                    :y1="doorTopHeight1 + 60.97"
                    :x2="doorLeftWidth + 119.89"
                    :y2="doorLeftWidth + 60.97"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-2"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 64.2"
                    :y1="doorTopHeight1 + 240.24"
                    :x2="doorLeftWidth + 119.89"
                    :y2="doorTopHeight1 + 240.24"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-3"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 64.2"
                    :y1="doorTopHeight1 + 150.6"
                    :x2="doorLeftWidth + 119.96"
                    :y2="doorTopHeight1 + 150.6"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-4"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 64.2"
                    :y1="doorTopHeight1 + 105.34"
                    :x2="doorLeftWidth + 119.96"
                    :y2="doorTopHeight1 + 105.34"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-5"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 64.2"
                    :y1="doorTopHeight1 + 195.87"
                    :x2="doorLeftWidth + 119.96"
                    :y2="doorTopHeight1 + 195.87"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-6"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 38.72"
                    :y1="doorTopHeight1 + 260.06"
                    :x2="doorLeftWidth + 40.53"
                    :y2="doorTopHeight1 + 260.06"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-7"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 38.72"
                    :y1="doorTopHeight1 + 41.15"
                    :x2="doorLeftWidth + 40.53"
                    :y2="doorTopHeight1 + 41.15"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-8"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 51.38"
                    :y1="doorTopHeight1 + 54.87"
                    :x2="doorLeftWidth + 51.38"
                    :y2="doorTopHeight1 + 246.37"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="glass-gradient"
                    :x1="doorLeftWidth + 51.38"
                    :y1="doorTopHeight1 + 241.18"
                    :x2="doorLeftWidth + 51.38"
                    :y2="doorTopHeight1 + 60.03"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>

    <g id="V31">
      <!-- <g v-if="showVariant === 'outside' && showInox"> -->
      <g v-if="showInox">
        <rect id="inox"
              class="cls-4"
              :x="doorLeftWidth + 63.5"
              :y="doorTopHeight1 + 59.5"
              :width="inoxW"
              height="1.87"/>
              
        <rect id="inox-2"
              data-name="inox"
              class="cls-5"
              :x="doorLeftWidth + 63.5"
              :y="doorTopHeight1 + 236.1"
              :width="inoxW"
              height="1.87"/>

        <rect id="inox-3"
              data-name="inox"
              class="cls-6"
              :x="doorLeftWidth + 63.5"
              :y="doorTopHeight1 + 149.81"
              :width="inoxW"
              height="1.87"/>

        <rect id="inox-4"
              data-name="inox"
              class="cls-7"
              :x="doorLeftWidth + 63.5"
              :y="doorTopHeight1 + 104.54"
              :width="inoxW"
              height="1.87"/>

        <rect id="inox-5"
              data-name="inox"
              class="cls-8"
              :x="doorLeftWidth + 63.5"
              :y="doorTopHeight1 + 195"
              :width="inoxW"
              height="1.87"/>

        <rect id="inox-6"
              data-name="inox"
              class="cls-9"
              :x="doorLeftWidth + 38"
              :y="doorTopHeight1 + 238.33"
              width="1.81"
              :height="inoxH"/>

        <rect id="inox-7"
              data-name="inox"
              class="cls-10"
              :x="doorLeftWidth + 38"
              :y="inoxY"
              width="1.81"
              :height="inoxH"/>
      </g>

      <!-- <g v-if="showVariant === 'inside' && showInox">
        <rect id="inner-inox"
              class="cls-11"
              :x="doorLeftWidth + 33.63"
              :y="doorTopHeight1 + 54.87"
              width="35.5"
              height="191.5"/>
      </g> -->

      <rect id="glass"
            class="cls-12"
            :x="doorLeftWidth + 38.11"
            :y="doorTopHeight1 + 59.18"
            width="24.72"
            height="178.74"/>
    </g>
  </g>
</template>


<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
    'showVariant',
    'laminationId',
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4,
    }
  },
  computed: {
    inoxY() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leafOffset
    },
    inoxH() {
      return this.showBg ?
        (this.doorHeight - 181.15 - this.inoxOffset * 2) / 2 :
        (this.doorHeight - 181.15 - this.leafOffset * 2) / 2
    },
    inoxW() {
      return this.showBg ?
        this.doorWidth - this.inoxOffset - 64.2 :
        this.doorWidth - this.leafOffset - 64.2
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 150
    }
  }
}
</script>
